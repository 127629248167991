<template>
  <div class="email_box">
    <div class="e_top_img">
      <img src="../../assets/img/email_img.png" alt="" />
    </div>
    <div class="e_main">
      <div class="e_title1">您好！</div>
      <div class="e_title2">
        为了确保面试顺利，请填写以下信息：
      </div>
      <div class="e_title3">您是否继续参加面试？</div>
      <div class="e_select_box">
        <el-checkbox
          v-model="is_attend.is_attend"
          @change="change_checkbox(1)"
          :label="1"
          >需要参加</el-checkbox
        >
        <template v-if="is_attend.is_attend[0]==1">
          <div class="is_c_box">
            <div class="is_c_box_1" v-if="duration">预估面试时长</div>
            <div class="" v-if="duration">
              {{ duration/1000/60 }} 分钟
            </div>

            <div class="is_c_box_1" v-if="suggestion_start&&suggestion_end">建议面试时间</div>
            <div class="" v-if="suggestion_start&&suggestion_end">
              <el-radio v-model="suggestion_act" @change="top_time_click(suggestion_start,suggestion_end)">
                <span class="time_box">{{ time_format(suggestion_start) }}</span>
              </el-radio>
            </div>

            <div class="is_c_box_1" v-if="list.length">如建议面试时间无法参加面试，请从以下时间段选择可参加面试时间</div>
            <div class="is_c_">
              <template v-if="list.length">
                <div style="margin-top:10px;">
                  <el-button type="primary" size="mini" @click="open_time_dlg">选择时间</el-button>
                </div>

              </template>
              <div class="time_box2">
                选中的时间为
                <span v-if="act_time.start">{{ time_format(act_time.start) }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="is_c_box_1">
              若以上时间均不能参加，请提供可参加时间段
            </div>
            <div class="is_c_time">
                <el-date-picker
                  v-model="is_attend.start_time"
                  type="datetime"
                  value-format="timestamp"
                  @change="tima_change"
                  :picker-options="pickerOptions"
                  placeholder="请选择开始时间">
              </el-date-picker>
              <!-- <span class="time_center"> - </span>
              <el-date-picker
                  disabled
                  v-model="is_attend.end_time"
                  type="datetime"
                  value-format="timestamp"
                  placeholder="请选择结束时间">
              </el-date-picker> -->
            </div>
            <div class="time_btn_txt">此时间段无法确保安排面试，请知悉</div>
            <!-- <div>{{ time_str }}</div> -->
          </div>
        </template>
      </div>

      <div class="e_select_box">
        <el-checkbox
          v-model="is_attend.is_attend"
          @change="change_checkbox(0)"
          :label="0"
          >不参加</el-checkbox
        >
        <template v-if="is_attend.is_attend[0]==0">
          <div class="is_c_box">
            <div class="is_c_box_1">请输入原因</div>
            <div class="is_c_">
              <div class="e_radio c2_inp">
                <el-input
                  v-model.trim="is_attend.refuse_reason"
                  placeholder="请输入原因"
                ></el-input>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div>
        <el-button
          type="primary"
          class="e_submit_btm"
          @click="is_attend_confirm"
          >提交</el-button
        >
      </div>
    </div>

    <!-- 选择时间 -->
    <el-dialog
      title=" "
      :visible.sync="act_time_dlg"
      width="500px"
      top="20%">
      <div class="">
        <div class="e_radio" v-for="(item,index) in list" :key="index">
          <el-radio :disabled="!item.usable" v-model="item.er_radio_act" @change="time_click(item.start,item.end)">
            <span class="time_box">{{ time_format(item.start) }} ~ {{ time_format(item.end) }}</span>
          </el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        &nbsp;
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {isAttend,c_getWorkOrderTime} from '@/api/api';
export default {
  data() {
    return {
        start:"",
        end:"",
        is_attend: {
          is_attend: [],
          refuse_reason: "",
          start_time:'',
          end_time:'',
        },
        order_id:"",
        candidate_userid:"",
        duration:'',
        list:[],
        act_time:{
          start:"",
          end:"",
        },
        act_time_dlg:false,
        pickerOptions:{
          disabledDate(time){
            return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
          }
        },
        suggestion_start:'',
        suggestion_end:'',
        suggestion_act:false,
    };
  },
  mounted() {
    // https://candidate.hibuddy.com.cn/?order_id=6523e21beef6f61c8e51ccb3&candidate_userid=651239424cfbbc937931e27e
    if (window.location.href.indexOf("?") !== -1) {
      const url = new URL(window.location.href);
      const order_id = url.searchParams.get("order_id");
      const candidate_userid = url.searchParams.get("candidate_userid");
      this.order_id = order_id;
      this.candidate_userid = candidate_userid;
    }
    this.order_id && this.get_list();
  },
  methods: {
    async get_list(){
      const res = await c_getWorkOrderTime({order_id:this.order_id,candidate_userid:this.candidate_userid});
      if(res.data.code!=200){
          return this.msg("error",res.data.msg);
      }
      this.duration = res.data.data.duration;
      let list = res.data.data.list;
      let arr=[];
      for (const item of list) {
        item['er_radio_act'] = false;
        arr.push(item);
      }
      this.list = arr;
      this.suggestion_start = res.data.data.suggestion_start;
      this.suggestion_end = res.data.data.suggestion_end;
    },
    change_checkbox(v) {
      this.is_attend.is_attend = [v];
      this.is_attend.refuse_reason="";
      this.is_attend.start_time="";
      this.is_attend.end_time="";
      this.act_time.start='';
      this.act_time.end='';
      this.reset_arr();
    },
    tima_change(v){
        v;
        if(this.is_attend.start_time){
          this.is_attend.end_time = this.is_attend.start_time + this.duration;
          this.act_time.start = this.is_attend.start_time;
          this.act_time.end = this.is_attend.end_time;
        }
        if(!this.is_attend.start_time){
          this.is_attend.end_time="";
          this.act_time.start="";
          this.act_time.end="";
        }
        this.reset_arr();
    },
    async is_attend_confirm() {
      if (!this.is_attend.is_attend.length) {
        return this.msg("error", "您是否继续参加面试？");
      }
      if (this.is_attend.is_attend[0] == 1) {//参加
        if (!this.act_time.start) {
          return this.msg("error", "请选择时间");
        }
      } else {
        if (!this.is_attend.refuse_reason) {
          return this.msg("error", "请输入拒绝理由");
        }
      }
      const res = await isAttend({
        order_id:this.order_id,
        candidate_userid:this.candidate_userid,
        is_attend:this.is_attend.is_attend[0],
        start_time:this.act_time.start,
        refuse_reason:this.is_attend.refuse_reason,
        });
        if(res.data.code!=200){
        return this.msg("error",res.data.msg);
        }
        this.msg("success","操作成功");
        // window.location.reload();
        this.is_attend.is_attend = [];
        this.is_attend.start_time="";
        this.is_attend.end_time="";
        this.is_attend.refuse_reason="";
        this.act_time.start="";
        this.act_time.end="";
        this.suggestion_act=false;
        this.get_list();
    },
    open_time_dlg(){
      this.suggestion_act = false;
      this.suggestion_act=false;
      this.act_time_dlg = true;
      this.is_attend.start_time='';
    },
    time_click(start,end){
      this.act_time.start = start;
      this.act_time.end = end;
      this.act_time_dlg = false;
      this.is_attend.start_time='';
    },
    top_time_click(start,end){
      this.act_time.start = start;
      this.act_time.end = end;
      this.reset_arr();
      this.is_attend.start_time='';
    },
    reset_arr(){
      let list = this.list;
      let arr=[];
      for (const item of list) {
        item['er_radio_act'] = false;
        arr.push(item);
      }
      this.list = arr;
    },

    
  },
};
</script>

<style scoped>
.email_box{
  height: 100vh;
}
/* *{
    margin: 0;
    padding: 0;
    font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
} */
.e_top_img {
  /* height: 200px;
  background-color: pink; */
}
.e_top_img img {
  width: 100%;
}
.e_main {
  width: 75%;
  max-width: 800px;
  margin: 30px auto;
}
.e_title1 {
  color: #333;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}
.e_title2 {
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 40px;
}
.e_title3 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.e_select_box {
  border-radius: 6px;
  background: #f4f5fa;
  box-sizing: border-box;
  padding: 35px;
  margin-bottom: 40px;
}
.is_c_box_1 {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}
.e_select_box .el-checkbox__input.is-checked + .el-checkbox__label,
.e_select_box .el-checkbox__label {
  color: #333;
  font-size: 16px;
}
.e_radio {
  margin-top: 15px;
}
.e_radio .el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
.time_center {
  box-sizing: border-box;
  padding: 0 15px;
}
.is_c_time {
  margin-top: 15px;
}
.time_btn_txt {
  margin-top: 10px;
  color: red;
  font-size: 12px;
  font-weight: 400;
}
.e_radio .el-radio {
  color: #a9a9a9;
}
.c2_inp {
  max-width: 400px;
}
.e_submit_btm,
.e_submit_btm:hover,
.e_submit_btm:focus,
.e_submit_btm:active,
.e_submit_btm:valid {
  min-width: 440px;
  height: 50px;
  background: #0a76e2;
  border-color: #0a76e2;
  color: #fff;
}

.is_c_time >>> .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    border-color: #DCDFE6;
    color: #606266;
    cursor: not-allowed;
}
.time_box{
  cursor: pointer;
  margin-bottom: 10px;
  color: #409EFF;
}
.time_box2{
  margin-top: 10px;
  color: red;
}

</style>
